export function redirectStatusCode500() {
  window.location.href = "https://www.ewe.de/500";
}

export function redirectError(redirectUri, error) {
  window.location.replace(`${redirectUri}?error=${error.toString()}`);
}

export async function storeTokens(
  authorization_code,
  idToken,
  accessToken,
  refreshToken,
) {
  /*
   * Call storage endpoint to store tokens in DynamoDB
   */
  var response = await fetch("/pkce/storage", {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({
      authorizationCode: authorization_code,
      idToken,
      accessToken,
      refreshToken,
    }),
  });

  return response;
}
