import React from "react";
import styled from "@emotion/styled";
import { I18n, ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { AuthPiece } from "aws-amplify-react";
import { oneLineTrim } from "common-tags";
import { ErrorContainer } from "@ewe-it/ewe-design-react";

import { Box, ScrollToTop } from "@ewe-it/ewe-design-react";

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Link,
  InputLabel,
  Input,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
  FormField,
  Hint,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import { auth } from "aws-amplify-react/lib/Amplify-UI/data-test-attributes";

import { getUrlParameter, scrollToTop } from "@ewe-it/ewe-frontend-utils";
import { SubmitButton } from "@ewe-it/ewe-design-react";

const logger = new Logger("CustomConfirmSignUp");

const TextBox = styled(Box)`
  padding-bottom: 20px;
`;

const Text = styled.span`
  font-size: 13px;
  color: #333333;
`;

class CustomConfirmSignUp extends AuthPiece {
  constructor(props) {
    super(props);

    this._validAuthStates = ["confirmSignUp"];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resend = this.resend.bind(this);

    this.state = {
      code: getUrlParameter("ewebc") || "",
      loading: false,
      error: null,
    };
  }

  async componentDidMount() {
    const username = getUrlParameter("username");

    // when the following URL is opened, we auto-confirm the user's account
    // and make them sign in again while prefilling their username
    // /confirm-signup?ewebc=<confirmation-code>&username=<username>&client_id=<client-id>
    if (username && this.state.code) {
      this.setState({ loading: true });
      await Auth.confirmSignUp(username, this.state.code).then(
        result => {
          this.setState({ loading: false });
          if (result === "SUCCESS") {
            // redirect user to sign-in page
            // This needs a hard refresh so amplify is notified
            const clientId = getUrlParameter("client_id");
            window.location.href = `/oauth2/authorize?client_id=${clientId}&username=${encodeURIComponent(
              username,
            )}`;
          }
        },
        error => {
          console.log(error);
          this.error(error);
        },
      );
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    const username = this.usernameFromAuthData() || this.inputs.username;
    const { authData, validationData } = this.props;
    const { password } = authData;

    const { code } = this.state;
    if (!Auth || typeof Auth.confirmSignUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }

    // Ignore any whitespace in the code since Cognito expects codes without
    // whitespace and doesn't remove whitespace on its own.
    //
    // Removing whitespace prevents the following error message:
    //   1 validation error detected: Value ' XXX XXX' at 'confirmationCode'
    //   failed to satisfy constraint:
    //   Member must satisfy regular expression pattern: [\S]+
    //
    // In Regular Expressions, \s is the "opposite" of \S.
    //   \S matches all non-whitespace characters, while
    //   \s matches all whitespace characters.
    // We use \s to remove all whitespace to ensure the string matches [\S]+.
    const codeWithoutWhitespace = code.replace(/\s/g, "");
    try {
      this.setState({ loading: true, error: null });
      await Auth.confirmSignUp(username, codeWithoutWhitespace);

      const authData = await Auth.signIn({
        username,
        password,
        validationData,
      });

      // Abweichung von amplify
      // Set the login cookies so users are signed in on other applications
      // immediately after they confirm their account.
      // These cookies are also set in redirect.js for existing accounts.
      const session = await Auth.currentSession();
      const expire = new Date(
        (session.getIdToken().getIssuedAt() + 60 * 60 * 24) * 1000,
      );
      const expires = expire.toGMTString();
      const secure = process.env.REACT_APP_COOKIE_DOMAIN !== "localhost";

      const loginSessionCookie = oneLineTrim`
        loginsession=loggedin;
        expires=${expires};
        domain=${process.env.REACT_APP_COOKIE_DOMAIN};
        path=/;
        ${secure ? "secure;" : ""}
        SameSite=lax
        `;
      document.cookie = loginSessionCookie;

      if (authData && authData.attributes && authData.attributes.sub) {
        const loginSubCookie = oneLineTrim`
          loginsub=${authData.attributes.sub};
          expires=${expires};
          domain=${process.env.REACT_APP_COOKIE_DOMAIN};
          path=/;
          ${secure ? "secure;" : ""}
          SameSite=lax
          `;
        document.cookie = loginSubCookie;
      }

      this.changeState("signedIn");
    } catch (error) {
      this.setState({ loading: false });
      scrollToTop();
      this.error(error);
    }
  }

  resend() {
    const username = this.usernameFromAuthData() || this.inputs.username;
    if (!Auth || typeof Auth.resendSignUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    Auth.resendSignUp(username)
      .then(() => {
        logger.debug("code resent");
        super.error(I18n.get("Code resent!"));
      })
      .catch(err => this.error(err));
  }

  handleCodeStateChange = event => {
    this.setState({ code: event.target.value });
  };

  showComponent(theme) {
    const username = this.usernameFromAuthData();
    const { code } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormSection theme={theme} data-test={auth.confirmSignUp.section}>
          <ScrollToTop key="confirmation-sign-up" />
          <SectionHeader
            theme={theme}
            data-test={auth.confirmSignUp.headerSection}
          >
            {I18n.get("Confirm Sign Up")}
          </SectionHeader>
          <SectionBody theme={theme} data-test={auth.confirmSignUp.bodySection}>
            <TextBox>
              {this.state.error ? (
                <ErrorContainer>{this.state.error}</ErrorContainer>
              ) : (
                <Text>
                  {I18n.get(
                    "The verification code has been sent to your specified email address.",
                  )}
                </Text>
              )}
            </TextBox>

            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get(this.getUsernameLabel())} *
              </InputLabel>
              <Input
                placeholder={I18n.get("Username")}
                theme={theme}
                key="username"
                name="username"
                autoComplete="username"
                autoCapitalize="off"
                onChange={this.handleInputChange}
                disabled={Boolean(username)}
                value={username ? username : ""}
                data-test={auth.confirmSignUp.usernameInput}
              />
            </FormField>

            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get("Confirmation Code")} *
              </InputLabel>
              <Input
                autoFocus
                placeholder={I18n.get("Enter your code")}
                theme={theme}
                key="code"
                name="code"
                autoComplete="off"
                disabled={this.state.loading}
                value={code}
                onChange={this.handleCodeStateChange}
                data-test={auth.confirmSignUp.confirmationCodeInput}
              />
              <Hint theme={theme}>
                {I18n.get("Lost your code? ")}
                <Link
                  theme={theme}
                  onClick={this.resend}
                  data-test={auth.confirmSignUp.resendCodeLink}
                >
                  {I18n.get("Resend Code")}
                </Link>
              </Hint>
            </FormField>
          </SectionBody>
          <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
              <SubmitButton
                data-test={auth.confirmSignUp.confirmButton}
                loading={this.state.loading}
              >
                {I18n.get("Confirm")}
              </SubmitButton>
            </SectionFooterPrimaryContent>
            <SectionFooterSecondaryContent theme={theme}>
              <Link
                theme={theme}
                onClick={() => this.changeState("signIn")}
                data-test={auth.confirmSignUp.backToSignInLink}
              >
                {I18n.get("Back to Sign In")}
              </Link>
            </SectionFooterSecondaryContent>
          </SectionFooter>
        </FormSection>
      </form>
    );
  }
}

export default CustomConfirmSignUp;
