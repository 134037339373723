import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import "./assets/globals.css";
import App from "./components/app";
import * as serviceWorker from "./service-worker";
import { name } from "../package.json";

console.log(`Name: ${name}`);
console.log(`Version: ${process.env.REACT_APP_CODEBUILD_BUILD_NUMBER}`);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
