import React from "react";
import styled from "@emotion/styled";
import { I18n, JS, ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import {
  AuthPiece,
  SignUp,
  ForgotPassword,
  FederatedButtons,
} from "aws-amplify-react";

import { Box } from "@ewe-it/ewe-design-react";
import {
  FormSection,
  FormField,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Link,
  Hint,
  Input,
  InputLabel,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import { auth } from "aws-amplify-react/lib/Amplify-UI/data-test-attributes";
import { SubmitButton, ScrollToTop } from "@ewe-it/ewe-design-react";
import { scrollToTop, getUrlParameter } from "@ewe-it/ewe-frontend-utils";

const logger = new Logger("CustomSignIn");

const TextBox = styled(Box)`
  padding-bottom: 20px;
`;

const Text = styled.span`
  font-size: 13px;
  color: #333333;
`;

export default class SignIn extends AuthPiece {
  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {};
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        this.changeState("signedIn", user);
      } else {
        user = Object.assign(user, data);
        this.changeState("verifyContact", user);
      }
    });
  }

  async signIn(event) {
    // avoid submitting the form
    if (event) {
      event.preventDefault();
    }

    const username = this.getUsernameFromInput() || "";
    const password = this.inputs.password;
    const validationData = this.props.validationData;

    if (!Auth || typeof Auth.signIn !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    this.setState({ loading: true });
    try {
      const user = await Auth.signIn({ username, password, validationData });
      logger.debug(user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        logger.debug("confirm user with " + user.challengeName);
        this.changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.debug("require new password", user.challengeParam);
        this.changeState("requireNewPassword", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", user.challengeParam);
        this.changeState("TOTPSetup", user);
      } else if (
        user.challengeName === "CUSTOM_CHALLENGE" &&
        user.challengeParam &&
        user.challengeParam.trigger === "true"
      ) {
        logger.debug("custom challenge", user.challengeParam);
        this.changeState("customConfirmSignIn", user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        this.changeState("confirmSignUp", { username, password });
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        this.changeState("resetPassword", { username }); // Abweichung von amplify
      } else {
        scrollToTop();
        this.error(err);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  renderInfoMessage(message) {
    return (
      <TextBox>
        <Text>{message}</Text>
      </TextBox>
    );
  }

  showComponent(theme) {
    const {
      authState,
      authData,
      hide = [],
      federated,
      onStateChange,
      onAuthEvent,
      override = [],
    } = this.props;

    const slimmed = getUrlParameter("slimmed");

    const hideSignUp =
      slimmed ||
      (!override.includes("SignUp") &&
        hide.some(component => component === SignUp));

    const hideForgotPassword =
      !override.includes("ForgotPassword") &&
      hide.some(component => component === ForgotPassword);

    const { infoMessage } = authData || {};

    return (
      <FormSection theme={theme} data-test={auth.signIn.section}>
        <ScrollToTop key="sign-in" />
        <SectionHeader theme={theme} data-test={auth.signIn.headerSection}>
          {slimmed ? (
            <>
              Bitte melden Sie sich mit den
              <br />
              <strong>Mein EWE - Energie </strong>
              Zugangsdaten an
            </>
          ) : (
            I18n.get("Sign in to your account")
          )}
        </SectionHeader>
        <FederatedButtons
          federated={federated}
          theme={theme}
          authState={authState}
          onStateChange={onStateChange}
          onAuthEvent={onAuthEvent}
        />
        <form onSubmit={this.signIn}>
          <SectionBody theme={theme}>
            {infoMessage && this.renderInfoMessage(infoMessage)}
            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get(this.getUsernameLabel())} *
              </InputLabel>
              <Input
                defaultValue={this.state.username}
                autoFocus={
                  // auto-focus unless a username is provided through the url
                  // That happens in case a user is confirming their account.
                  getUrlParameter("username") === null
                }
                placeholder={I18n.get("Enter your username")}
                theme={theme}
                key="username"
                name="username"
                type="text"
                autoComplete="username"
                autoCapitalize="off"
                disabled={this.state.loading}
                onChange={this.handleInputChange}
                data-test={auth.genericAttrs.usernameInput}
              />
            </FormField>
            <FormField theme={theme}>
              <InputLabel theme={theme}>{I18n.get("Password")} *</InputLabel>
              <Input
                placeholder={I18n.get("Enter your password")}
                autoFocus={
                  // auto-focus when a username is provided through the url
                  // That happens in case a user is confirming their account.
                  getUrlParameter("username") !== null
                }
                theme={theme}
                key="password"
                type="password"
                name="password"
                autoComplete="current-password"
                disabled={this.state.loading}
                onChange={this.handleInputChange}
                data-test={auth.signIn.passwordInput}
              />
              {!hideForgotPassword && (
                <Hint theme={theme}>
                  {I18n.get("Forget your password? ")}
                  <Link
                    theme={theme}
                    onClick={() => this.changeState("forgotPassword")}
                    data-test={auth.signIn.forgotPasswordLink}
                  >
                    {I18n.get("Reset password")}
                  </Link>
                </Hint>
              )}
              <Hint theme={theme}>
                Benutzername vergessen?{" "}
                <Link
                  theme={theme}
                  onClick={() => this.changeState("forgotUsername")}
                >
                  Benutzername anfordern
                </Link>
              </Hint>
            </FormField>
          </SectionBody>
          <SectionFooter theme={theme} data-test={auth.signIn.footerSection}>
            <SectionFooterPrimaryContent theme={theme}>
              <SubmitButton
                type="submit"
                loading={this.state.loading}
                disabled={this.state.loading}
                data-test={auth.signIn.signInButton}
              >
                {I18n.get("Sign In")}
              </SubmitButton>
            </SectionFooterPrimaryContent>
            {!hideSignUp && (
              <SectionFooterSecondaryContent theme={theme}>
                {I18n.get("No account? ")}
                <br />
                <Link
                  theme={theme}
                  onClick={() => this.changeState("signUp")}
                  data-test={auth.signIn.createAccountLink}
                >
                  {I18n.get("Create account")}
                </Link>
              </SectionFooterSecondaryContent>
            )}
          </SectionFooter>
        </form>
      </FormSection>
    );
  }
}
